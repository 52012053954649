<template>
    <Modal
        class="wrap"
        v-model="modal"
        :title="$t('pages.personal.settingPassword')"
        :mask-closable="false"
        :width="387">
        <Form ref="formValidate" :model="formValidate" :rules="ruleValidate" inline>
            <template v-if="local_install">
                <FormItem label="" prop="password" style="width: 100%;">
                    <Input v-model="formValidate.oldPassword" autocomplete="new-password" size="large" type="password" :placeholder="$t('pages.personal.inputOldPassword')"></Input>
                </FormItem>
                <FormItem label="" prop="password" style="width: 100%;">
                    <Input v-model="formValidate.password" autocomplete="new-password" size="large" type="password" :placeholder="$t('pages.login.inputNewPassword')"></Input>
                </FormItem>
                <FormItem label="" prop="password" style="width: 100%;">
                    <Input v-model="formValidate.password2" autocomplete="new-password" size="large" type="password" :placeholder="$t('pages.login.inputNewPassword')"></Input>
                </FormItem>
            </template>
            <template v-else>
                <FormItem prop="message" style="width: 229px;">
                    <Input type="text" size="large" v-model="formValidate.message" :placeholder="$t('pages.login.inputCode')" />
                </FormItem>
                <span id="sms_login_verify" class="message-get" v-if="!sendMsgDisabled" style="width: 115px;" size="large" @click="getMesg('psw')">{{ $t('pages.login.getCode') }}</span>
                <Button v-if="sendMsgDisabled" style="width: 115px;" size="large" class="on" disabled>{{ countDownTime }}S</Button>
                <FormItem label="" prop="password" style="width: 100%;">
                    <Input v-model="formValidate.password" autocomplete="new-password" size="large" type="password" :placeholder="$t('pages.login.inputNewPassword')"></Input>
                </FormItem>
            </template>
        </Form>
        <div slot="footer" style="margin-right: 15%;">
            <Button type="primary" :loading="loading" @click="submit">{{$t('common.confirm')}}</Button>
        </div>
    </Modal> 
</template>
<script>
    import sha1 from 'sha1'

    export default {
        name: "",
        mixins: [],
        components: {},
        props: {
            show: {
                default: false
            },
            mobile: {
                default: ''
            },
            areaCode: {
                default: ''
            }
        },
        data () {
            return {
                modal: false,
                loading: false,
                formValidate: {
                    password: '',
                    message: ''
                },
                sendMsgDisabled: false,
                countDownTime: 60,
                timer: null
            }
        },
        computed: {
            ruleValidate() {
                return {
                    message: [
                        { required: true, type: 'string', message: this.$t('pages.login.inputCode'), trigger: 'blur' },
                        { type: 'string', pattern: /^\d{4}$/, message: this.$t('pages.login.input4Digits'), trigger: 'blur' }

                    ],
                    oldPassword: [
                        { required: true, type: 'string', min: 6, max: 30, message: this.$t('pages.personal.inputOldPassword'), trigger: 'blur' }
                    ],
                    password: [
                        { required: true, type: 'string', min: 6, max: 30, message: this.$t('pages.login.inputPassword'), trigger: 'blur' }
                    ],
                    password2: [
                        { required: true, type: 'string', min: 6, max: 30, message: this.$t('pages.login.inputPassword'), trigger: 'blur' }
                    ]
                }
            },
            local_install() {
                return this.$store.state.local_install
            }
        },
        watch: {
            show(val) {
                this.modal = val
                if (val && this.local_install) {
                    this.$set(this.formValidate, 'oldPassword', '')
                    this.$set(this.formValidate, 'password2', '')
                }
            },
            modal(val) {
                if (!val) {
                    this.cancel()
                }
            }
        },
        methods: {
            cancel() {
                this.$emit('cancel')
                this.formValidate = {
                    password: '',
                    message: ''
                }
                this.$refs['formValidate'].resetFields();
            },
            submit() {
                let params = {
                    mobile: this.mobile,
                    sms_code: this.formValidate.message,
                    password: sha1(this.formValidate.password),
                    area_code: this.areaCode
                },
                url = 'auth/reset_password_by_sms',
                method = 'post';
                if (this.local_install) {
                    if (this.formValidate.password2 !== this.formValidate.password) return this.$Message.error(this.$t('pages.personal.pwdErr'))
                    url = 'me/account/password'
                    method = 'put'
                    params = {
                        old_password: sha1(this.formValidate.oldPassword),
                        password: sha1(this.formValidate.password2)
                    }
                }
                this.$refs['formValidate'].validate((valid) => {
                    if (valid) {
                        this.loading = true
                        this.$api[method](url, params).then(() => {
                            this.$Message.success(this.$t('common.success'))
                            this.$emit('confirm')
                        }).finally(() => {
                            this.loading = false
                        })
                    }
                })
            },
            countDown() {
                if ((this.countDownTime--) <= 0) {
                    this.countDownTime = 60
                    this.sendMsgDisabled = false
                    clearInterval(this.timer)
                }
            },
            getMesg(sms_type) {
                let params = {
                    sms_type: sms_type,
                    area_code: this.areaCode
                }
                this.$api.get(`auth/captchas/sms/${this.mobile}`, { params }).then(({ code }) => {
                    if (code === 200) {
                        this.sendMsgDisabled = true
                        clearInterval(this.timer)
                        this.timer = setInterval(this.countDown, 1000)
                    }
                })
            }
        },
        beforeDestroy() {
            clearInterval(this.timer)
        },
        created() {}
    }
</script>
<style scoped lang="less">
.message-get {
    cursor: pointer;
    display: inline-block;
    width: 115px;
    height: 40px;
    background: #ececec;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 700;
    line-height: 40px;
    text-align: center;
}
</style>